import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { createContext } from 'react';
import { Maybe } from '../../types/shared';

export interface LocaleServiceContext {
  localeService: Maybe<GfaLocaleServiceV1>;
}

export const LocaleContext = createContext<LocaleServiceContext>({
  localeService: null,
});
