import { AuthServiceV2 } from '@oneaudi/audi-auth-service';
import { createContext } from 'react';
import { Maybe } from '../../types';

export interface AuthContextContent {
  authService: Maybe<AuthServiceV2>;
  accessToken: Maybe<string>;
  userId: Maybe<string>;
}

export const AuthContext = createContext<AuthContextContent>({
  authService: null,
  accessToken: null,
  userId: null,
});
