import { createMediaQuery, getResponsiveSpacing } from '@oneaudi/narown-utils-ui';
import styled from 'styled-components';

export const Content = styled.div`
  box-sizing: border-box;
  margin: 0;

  ${createMediaQuery({
    margin: {
      xxl: '0 auto',
    },

    'max-width': {
      xxl: '1784px',
    },
  })}
`;

export const Card = styled.div`
  position: relative;
  background-color: #fff;
  box-shadow: 0 4px 16px 2px rgba(0, 0, 0, 0.08);
  margin-top: ${getResponsiveSpacing('xxxl')};
  padding: 36px;

  ${createMediaQuery({
    padding: {
      m: '32px',
      l: '40px',
      x: '48px',
      xxl: '52px',
    },
    'padding-top': {
      l: '12px',
      xl: '0px',
    },
    'min-height': {
      xs: '300px',
      l: '400px',
    },
  })}
`;

export const CardLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  ${createMediaQuery({
    'align-items': {
      xl: 'center',
    },
    'flex-direction': {
      l: 'row',
    },
  })}
`;
