import fetch from 'isomorphic-fetch';
import { useEffect, useState } from 'react';
import { FetchOptions, isError } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const uniqid: (prefix?: string, suffix?: string) => string = require('uniqid');

export const useFetch = <T>(accessToken: string, url: string, options: FetchOptions) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { body, headers, method } = options;

  useEffect(() => {
    setLoading(true);
    setError('');
    if (accessToken) {
      fetch(url, {
        body,
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
          'request-id': uniqid('add-vehicle-'),
        },
        method: method || 'GET',
      })
        .then((response) => {
          if (response.ok) {
            return response.status === 204 ? Promise.resolve({}) : response.json();
          }
          throw new Error(response.status.toString());
        })
        .then((responseData: T | Error) => {
          if (isError(responseData)) {
            setError(responseData.message);
          } else {
            setData(responseData);
          }
        })
        .catch((e: Error) => {
          setError(e.message ? e.message : 'unexpected error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [accessToken]);

  return {
    data,
    loading,
    error,
  };
};
