import { isBreakpointXxl } from '@oneaudi/narown-utils-ui';
import { BreakPointSize } from '../../../styled/sizes';
import { Placement } from '../../../types';

export const getPopoverPlacement = (breakpoint: BreakPointSize): Placement => {
  if (isBreakpointXxl(breakpoint)) {
    return 'bottom';
  }
  return 'bottom right';
};
