export enum LanguageCountryKey {
  EN_US = 'en-US',
  EN_CA = 'en-CA',
  FR_CA = 'fr-CA',
}

export type RedirectMap = { [language in LanguageCountryKey]: string };

export const SupportRedirectMap: RedirectMap = {
  [LanguageCountryKey.EN_US]: '../support/contact-us.html',
  [LanguageCountryKey.EN_CA]: '../contact-us.html',
  [LanguageCountryKey.FR_CA]: '../contact-us.html',
};
