import { Loader } from '@audi/audi-ui-react';
import { isBreakpointMedOrLess } from '@oneaudi/narown-utils-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentBreakpoint } from '../../hooks';
import { AnimationStates } from '../../styled/animation.styles';
import { AddVehicleButton, AddVehicleButtonContainer, BackButton, ButtonLayout } from './styles';

export interface ConfirmVinProps {
  loading: boolean;
  vin: string;
  onAddVin: (v: string) => void;
  onEditVin: () => void;
}

interface AnimatedConfirmVinProps extends ConfirmVinProps {
  state: AnimationStates;
  onAnimationEnd: () => void;
}

export const ConfirmVin: React.FC<AnimatedConfirmVinProps> = ({
  loading,
  state,
  vin,
  onAddVin,
  onEditVin,
  onAnimationEnd,
}) => {
  const breakpoint = useCurrentBreakpoint();
  const { t } = useTranslation();

  return (
    <ButtonLayout state={state}>
      <BackButton
        data-testid="back-button"
        disabled={loading}
        id="nar-add-vehicle-back-button"
        state={state}
        onClick={onEditVin}
        stretch={isBreakpointMedOrLess(breakpoint)}
        variant="secondary"
      >
        {t('addVehicle.noEditVin')}
      </BackButton>
      <AddVehicleButtonContainer
        data-testid="add-button-container"
        state={state}
        onAnimationEnd={onAnimationEnd}
      >
        <AddVehicleButton
          data-testid="add-button"
          disabled={loading}
          id="nar-add-vehicle-add-button"
          state={state}
          onClick={() => {
            onAddVin(vin);
          }}
          stretch={isBreakpointMedOrLess(breakpoint)}
          variant="primary"
        >
          {loading ? (
            <Loader data-testid="loader" monochrome size="small" />
          ) : (
            <>{t('addVehicle.yesAddVehicle')}</>
          )}
        </AddVehicleButton>
      </AddVehicleButtonContainer>
    </ButtonLayout>
  );
};
