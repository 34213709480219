import { createMediaQuery, getResponsiveSpacing } from '@oneaudi/narown-utils-ui';
import styled from 'styled-components';

export const NBSP = '\u00A0';
export const BACKGROUND_POSITION_CSS_VAR = '--add-vehicle-background-position-offset';
const ANIMATE_TIME_IN_SEC = 1.5;

export type StaticBackgroundProps = {
  image: string;
  shouldAnimate?: boolean;
};

export const StaticBackground = styled.div<StaticBackgroundProps>`
  max-width: 1920px;
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  animation: ${({ shouldAnimate }) => shouldAnimate && `slideUpBackground ${ANIMATE_TIME_IN_SEC}s`};
  background-position-x: center;
  background-origin: content-box;
  background-position-y: ${({ shouldAnimate }) =>
    shouldAnimate && `var(${BACKGROUND_POSITION_CSS_VAR})`};
  background-size: 100%, 100%;

  ${createMediaQuery({
    [BACKGROUND_POSITION_CSS_VAR]: {
      xs: '-5%',
      s: '0%',
      m: '-5%',
    },
  })}

  @keyframes slideUpBackground {
    0% {
      background-position-y: 0;
    }
    100% {
      background-position-y: var(${BACKGROUND_POSITION_CSS_VAR});
    }
  }
`;

const containerHorizontalPaddings = {
  m: '32px',
  l: '52px',
  xl: '68px',
};

export const GradientContainer = styled.div`
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%, 100%;
  box-sizing: border-box;
  padding: ${getResponsiveSpacing('xxxl')} 15px;

  ${createMediaQuery({
    'background-image': {
      xs: `linear-gradient(
            180deg,
            rgba(242,242,242,0),
            rgba(242,242,242,0.12) 14%,
            rgba(242,242,242,0.24) 26%,
            #f2f2f2 48% 
          );`,
      m: `linear-gradient(
            180deg,
            rgba(242,242,242,0),
            rgba(242,242,242,0.12) 14%,
            rgba(242,242,242,0.24) 20%,
            #f2f2f2 42% 
          );`,
      l: `linear-gradient(
            180deg,
            rgba(242,242,242,0),
            rgba(242,242,242,0.12) 12%,
            rgba(242,242,242,0.24) 28%,
            #f2f2f2 54% 
          );`,
      xl: `linear-gradient(
            180deg,
            rgba(242,242,242,0),
            rgba(242,242,242,0.12) 14%,
            rgba(242,242,242,0.24) 32%,
            #f2f2f2 48% 
          );`,
      xxl: `linear-gradient(
            180deg,
            rgba(242,242,242,0),
            rgba(242,242,242,0.12) 8%,
            rgba(242,242,242,0.24) 24%,
            #f2f2f2 44% 
          );`,
    },
    'padding-left': containerHorizontalPaddings,
    'padding-right': containerHorizontalPaddings,
  })}
`;

export const BackgroundOverlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ContentContainer = styled.div``;
