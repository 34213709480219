import React from 'react';

import { AnimatedContainerProps, AnimationStates } from '../../styled/animation.styles';
import { ImageContainer } from './styles';

interface AnimatedImageContainerProps extends AnimatedContainerProps {
  altText: string;
  source?: string;
}

export const AnimatedImageContainer: React.FC<AnimatedImageContainerProps> = ({
  altText,
  source,
  state,
  onAnimationEnd,
}) => {
  if (state === AnimationStates.HIDDEN) {
    return null;
  }

  return (
    <ImageContainer alt={altText} src={source} state={state} onAnimationEnd={onAnimationEnd} />
  );
};
