import React from 'react';
import { ConfigurationContext, ConfigurationContextContent } from './ConfigurationContext';

export const ConfigurationContextProvider: React.FC<ConfigurationContextContent> = ({
  children,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  <ConfigurationContext.Provider value={{ ...props }}>{children}</ConfigurationContext.Provider>
);
