import { Text } from '@audi/audi-ui-react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimatedContainerProps, AnimationStates } from '../../styled/animation.styles';
import { Container, OuterContainer } from './styles';

export interface VinDetailsProps {
  model?: string;
  trim?: string;
  vin: string;
  year?: number;
  updateHeightReference: (h: number) => void;
}

interface AnimatedVinDetailsProps extends AnimatedContainerProps, VinDetailsProps {}

export const VinDetails: React.FC<AnimatedVinDetailsProps> = ({
  model,
  trim,
  vin,
  year,
  state,
  updateHeightReference,
  onAnimationEnd,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const heightRef = useRef(0);

  useEffect(() => {
    if (state !== AnimationStates.HIDDEN && containerRef.current) {
      updateHeightReference(containerRef.current.offsetHeight);
      heightRef.current = containerRef.current.offsetHeight;
    }
  });

  if (state === AnimationStates.HIDDEN) {
    return null;
  }

  return (
    <OuterContainer state={state} height={heightRef.current}>
      <Container
        data-testid="confirm-vin"
        state={state}
        onAnimationEnd={onAnimationEnd}
        ref={containerRef}
      >
        <Text spaceStackEnd="xs" variant="copy2">
          <Text
            as="strong"
            spaceInlineEnd="xs"
            variant="order3"
            weight="bold"
            data-testid="data-vehicle-model-name"
          >
            {model || ''}
          </Text>
          <span data-testid="data-vehicle-model-year">{year || ''}</span>
        </Text>
        <Text spaceStackEnd="l" variant="copy1" data-testid="data-vehicle-trim">
          {trim || ''}
        </Text>
        <Text variant="copy2" weight="bold">
          {t('addVehicle.vinNumberLabel')}
        </Text>
        <Text variant="copy2" data-testid="data-vehicle-vin">
          {vin}
        </Text>
      </Container>
    </OuterContainer>
  );
};
