import { LocalizedDataFetchOptions } from '../../types';

export const fetchOptions = (language?: string | null): LocalizedDataFetchOptions => {
  return {
    us: {
      addVehicle: (body: string) => ({
        body,
        headers: {
          'exchange-token-type': 'aoa',
        },
        method: 'POST',
      }),
      vinDetails: {
        headers: {
          'exchange-token-type': 'aoa',
        },
        method: 'GET',
      },
    },
    ca: {
      addVehicle: (body: string) => ({
        body,
        method: 'POST',
        headers: {
          'X-User-Language': language || 'en',
        },
      }),
      vinDetails: {
        method: 'GET',
        headers: {
          'X-User-Language': language || 'en',
        },
      },
    },
  };
};
