export enum MarketEnum {
  USA = 'USA',
  CAN = 'CAN',
}

export enum MarketString {
  US = 'us',
  CA = 'ca',
}

export enum Language {
  FR = 'fr',
  EN = 'en',
}
