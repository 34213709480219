import { useEffect, useRef } from 'react';

export const useRequestAnimationFrame = (callback: (v: number) => void) => {
  const animationRequestRef = useRef(0);
  const previousTimeRef = useRef(0);

  const step = (time: number) => {
    if (previousTimeRef.current) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    animationRequestRef.current = requestAnimationFrame(step);
  };

  useEffect(() => {
    animationRequestRef.current = requestAnimationFrame(step);
    return () => cancelAnimationFrame(animationRequestRef.current);
  }, []);
};
