import React, { PropsWithChildren } from 'react';
import { Button, Layout, Text } from '@audi/audi-ui-react';
import { ButtonLayout, ModalLayout } from './styles';
import { Modal } from '../Modal';
import { withAudiDesignSystemLight } from '../../componentWrappers';
import { Maybe } from '../../types';

type ErrorModalProps = {
  header: string;
  labelPrimary: string;
  labelSecondary: string;
  isOpen: boolean;
  modalRoot: Maybe<Element>;
  onClose: () => void;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
};

const modalLabelId = 'nar-add-vehicle-error-modal-header';
const modalDescriptionId = 'nar-add-vehicle-error-modal-description';

export const _ErrorModal: React.FC<ErrorModalProps & PropsWithChildren> = ({
  children,
  header,
  labelPrimary,
  labelSecondary,
  isOpen,
  modalRoot,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
}) => {
  return (
    <Modal
      canModalClose
      isOpen={isOpen}
      modalLabelId={modalLabelId}
      modalDescriptionId={modalDescriptionId}
      modalRoot={modalRoot}
      onClose={onClose}
    >
      <ModalLayout>
        <Layout align="stretch" direction="column" spaceInlineStart="l" spaceInlineEnd="s">
          <Text
            id={modalLabelId}
            variant="order4"
            weight="bold"
            spaceStackStart="l"
            spaceStackEnd="m"
          >
            {header}
          </Text>
          <Text id={modalDescriptionId} variant="copy2" spaceStackEnd="xl">
            {children}
          </Text>
          <ButtonLayout>
            <Button
              variant="primary"
              onClick={onPrimaryAction}
              data-testid="error-modal-primary-button"
            >
              {labelPrimary}
            </Button>
            <Button
              variant="secondary"
              onClick={onSecondaryAction}
              data-testid="error-modal-secondary-button"
            >
              {labelSecondary}
            </Button>
          </ButtonLayout>
        </Layout>
      </ModalLayout>
    </Modal>
  );
};

export const ErrorModal = withAudiDesignSystemLight(_ErrorModal);
