/* istanbul ignore file: testing unnecessary */
import React, { PropsWithChildren } from 'react';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { LocaleContext } from './LocaleContext';

export const LocaleContextProvider: React.FC<
  {
    localeService: GfaLocaleServiceV1;
  } & PropsWithChildren
> = ({
  localeService,
  children,
  // eslint-disable-next-line react/jsx-no-constructed-context-values
}) => <LocaleContext.Provider value={{ localeService }}>{children}</LocaleContext.Provider>;
