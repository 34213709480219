/**
 * This is the entry point for Feature Hub App integration
 */

import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import type { Logger } from '@feature-hub/logger';
import { ReactFeatureApp } from '@feature-hub/react';
import { AuthServiceProviderV2, AuthServiceV2 } from '@oneaudi/audi-auth-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import React from 'react';
import App from './FeatureApp';
import { mergeConfig, type AppConfig } from './config';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'vw:authService'?: AuthServiceProviderV2;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      'vw:authService': '2.2.0',
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
    },
    externals: {
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '*',
    },
  },

  create: ({
    baseUrl,
    config: remoteConfig,
    featureServices,
  }: FeatureAppEnvironment<FeatureServiceDependencies, AppConfig>) => {
    const { lambdaBaseUrl, endpoints, narGateway } = mergeConfig(remoteConfig as AppConfig);

    const localeService = featureServices['gfa:locale-service'];
    const logger = featureServices['s2:logger'];
    const authServiceProvider = featureServices['vw:authService'];
    const authService = authServiceProvider?.register('myaudi');

    logger?.info('Feature App - Add Vehicle - created.');

    return {
      render: () => {
        return (
          <App
            authService={authService as AuthServiceV2}
            baseUrl={baseUrl as string}
            lambdaBaseUrl={lambdaBaseUrl}
            lambdaEndPoints={endpoints}
            localeService={localeService}
            narGateway={narGateway}
          />
        );
      },
    };
  },
};

export default featureAppDefinition;
