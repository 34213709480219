import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimationStates } from '../../styled/animation.styles';

import { CardConfirmVinSummary, CardEnterVinSummary } from './styles';

interface SummaryContentProps {
  isEditing: boolean;
  onStateSwitch: (v: boolean) => void;
}

export const SummaryContent: React.FC<SummaryContentProps> = ({ isEditing, onStateSwitch }) => {
  const [shouldShowEnterVin, setShouldShowEnterVin] = useState(isEditing);
  const [enterVinSummaryState, setEnterVinSummaryState] = useState(AnimationStates.IDLE);
  const [confirmVinSummaryState, setConfirmVinSummaryState] = useState(AnimationStates.HIDDEN);
  const { t } = useTranslation();

  useEffect(() => {
    if (isEditing) {
      setConfirmVinSummaryState(AnimationStates.LEAVING);
    } else {
      setEnterVinSummaryState(AnimationStates.LEAVING);
    }
  }, [isEditing]);

  const handleInputSummaryAnimationEnd = () => {
    if (isEditing) {
      setEnterVinSummaryState(AnimationStates.IDLE);
    } else {
      setShouldShowEnterVin(false);
      setConfirmVinSummaryState(AnimationStates.ENTERING);
      onStateSwitch(false);
    }
  };

  const handleConfirmSummaryAnimationEnd = () => {
    if (isEditing) {
      setEnterVinSummaryState(AnimationStates.ENTERING);
      setShouldShowEnterVin(true);
      onStateSwitch(true);
    } else {
      setConfirmVinSummaryState(AnimationStates.IDLE);
    }
  };

  if (shouldShowEnterVin) {
    return (
      <CardEnterVinSummary
        data-testid="enter-vin-summary"
        state={enterVinSummaryState}
        onAnimationEnd={handleInputSummaryAnimationEnd}
      >
        {t('addVehicle.activateKeyFeatures')}
      </CardEnterVinSummary>
    );
  }
  return (
    <CardConfirmVinSummary
      data-testid="confirm-vin-summary"
      state={confirmVinSummaryState}
      onAnimationEnd={handleConfirmSummaryAnimationEnd}
    >
      {t('addVehicle.isThisVehicle')}
    </CardConfirmVinSummary>
  );
};
