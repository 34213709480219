import React, { useEffect, useState } from 'react';
import { AnimationStates } from '../../styled/animation.styles';
import { EnterVin, EnterVinProps } from '../EnterVin';
import { VinDetails, VinDetailsProps } from '../VinDetails';

interface FormHandlerProps extends EnterVinProps, VinDetailsProps {
  isEditingVin: boolean;
}

export const FormHandler: React.FC<FormHandlerProps> = ({
  isEditingVin,
  model,
  trim,
  vin,
  year,
  invalid,
  validationMessageKey,
  updateHeightReference,
  onVinChange,
  onSubmitVin,
  onValidateVin,
}) => {
  const [enterVinState, setEnterVinState] = useState(AnimationStates.IDLE);
  const [vinDetailsState, setVinDetailsState] = useState(AnimationStates.HIDDEN);
  useEffect(() => {
    if (isEditingVin) {
      if (vinDetailsState === AnimationStates.IDLE) {
        setVinDetailsState(AnimationStates.LEAVING);
      }
    } else {
      setEnterVinState(AnimationStates.LEAVING);
    }
  }, [isEditingVin]);

  const handleEnterVinAnimationEnd = () => {
    if (isEditingVin) {
      setEnterVinState(AnimationStates.IDLE);
    } else {
      setEnterVinState(AnimationStates.HIDDEN);
      setVinDetailsState(AnimationStates.ENTERING);
    }
  };

  const handleVinDetailsAnimationEnd = () => {
    if (isEditingVin) {
      setEnterVinState(AnimationStates.ENTERING);
      setVinDetailsState(AnimationStates.HIDDEN);
    } else {
      setVinDetailsState(AnimationStates.IDLE);
    }
  };

  return (
    <>
      <EnterVin
        state={enterVinState}
        invalid={invalid}
        validationMessageKey={validationMessageKey}
        vin={vin}
        onAnimationEnd={handleEnterVinAnimationEnd}
        onVinChange={onVinChange}
        onSubmitVin={onSubmitVin}
        onValidateVin={onValidateVin}
      />
      <VinDetails
        model={model}
        trim={trim}
        vin={vin}
        year={year}
        state={vinDetailsState}
        updateHeightReference={updateHeightReference}
        onAnimationEnd={handleVinDetailsAnimationEnd}
      />
    </>
  );
};
