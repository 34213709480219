import React, { PropsWithChildren } from 'react';
import { Modal as ResponsiveModal } from 'react-responsive-modal';

import { CloseIcon } from '@oneaudi/narown-utils-ui';
import { Maybe } from '../../types/shared';

interface ModalProps {
  canModalClose: boolean;
  closeIconSize?: string;
  isOpen: boolean;
  modalDescriptionId: string;
  modalLabelId: string;
  modalRoot: Maybe<Element>;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps & PropsWithChildren> = ({
  canModalClose,
  children,
  closeIconSize,
  isOpen,
  modalDescriptionId,
  modalLabelId,
  modalRoot,
  onClose,
}) => (
  <ResponsiveModal
    open={isOpen}
    onClose={onClose}
    closeIconId="close-cancel-modal"
    closeIcon={<CloseIcon width={closeIconSize} height={closeIconSize} />}
    showCloseIcon={canModalClose}
    closeOnOverlayClick={canModalClose}
    // false because the library won't allow the value to change dynamically, close on escape
    // should be handled by a parent component
    closeOnEsc={false}
    animationDuration={200}
    ariaLabelledby={modalLabelId}
    ariaDescribedby={modalDescriptionId}
    container={modalRoot}
    classNames={{
      modal: 'nar-add-vehicle-modal',
      closeButton: 'nar-add-vehicle-close-button',
    }}
    center
  >
    {children}
  </ResponsiveModal>
);

Modal.defaultProps = { closeIconSize: '16' };
