import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './app/locales/en/common.json';
import translationFR from './app/locales/fr/common.json';

const resources = {
  en: {
    common: translationEN,
  },
  fr: {
    common: translationFR,
  },
};
// eslint-disable-next-line no-void
// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: 'common',
    detection: {
      order: ['path', 'querystring'],
      lookupQuerystring: 'lng',
      lookupFromPathIndex: 2,
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    ns: 'common',
    resources,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
