import { Loader } from '@audi/audi-ui-react';
import { isBreakpointMedOrLess } from '@oneaudi/narown-utils-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentBreakpoint } from '../../hooks';
import { isVinValid } from './isVinValid';
import { SubmitVinButton } from './styles';

export const onSubmit = (
  vin: string,
  submitVin: (v: string) => void,
  onValidateVin: (v: boolean) => void,
) => {
  return () => {
    const isValid = isVinValid(vin);
    onValidateVin(!isValid);
    if (isValid) {
      submitVin(vin);
    }
  };
};

export interface SubmitVinProps {
  disabled: boolean;
  loading: boolean;
  vin: string;
  onSubmitVin: (v: string) => void;
  onValidateVin: (v: boolean) => void;
}

export const SubmitVin: React.FC<SubmitVinProps> = ({
  disabled,
  loading,
  vin,
  onSubmitVin,
  onValidateVin,
}) => {
  const { t } = useTranslation();
  const breakpoint = useCurrentBreakpoint();
  const validateAndSubmit = onSubmit(vin, onSubmitVin, onValidateVin);

  return (
    <SubmitVinButton
      data-testid="submit-button"
      id="nar-add-vehicle-submit-button"
      disabled={disabled || loading}
      onClick={validateAndSubmit}
      stretch={isBreakpointMedOrLess(breakpoint)}
      variant="primary"
    >
      {loading ? (
        <Loader data-testid="loader" monochrome size="small" />
      ) : (
        <>{t('addVehicle.confirmVin')}</>
      )}
    </SubmitVinButton>
  );
};
