import * as React from 'react';
import { Text } from '@audi/audi-ui-react';

import { withAudiDesignSystemLight } from '../../componentWrappers/withAudiDesignSystem';

const _AddVehicleError: React.FC = () => {
  return (
    <div data-testid="add-vehicle-error">
      <Text variant="order4" weight="bold" spaceStackStart="xl">
        Error
      </Text>
      <Text spaceStackStart="s">A problem has ocurred.</Text>
    </div>
  );
};

export const AddVehicleError = withAudiDesignSystemLight(_AddVehicleError);
