import { AuthServiceV2 } from '@oneaudi/audi-auth-service';
import decode from 'jwt-decode';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Maybe } from '../../types';
import { AuthContext } from './AuthContext';

export const AuthContextProvider: React.FC<
  {
    authService: Maybe<AuthServiceV2>;
  } & PropsWithChildren
> = ({ authService, children }) => {
  const [accessToken, setAccessToken] = useState('');
  const [authError, setAuthError] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (!accessToken) {
      authService
        ?.getAccessToken()
        .then((rawAccessToken: string) => {
          setAccessToken(rawAccessToken);
          const decoded: { sub: string } = decode(rawAccessToken);
          setUserId(decoded.sub);
        })
        .catch(setAuthError);
    }
  }, [accessToken]);

  if (authError) throw new Error(authError);

  const authBag = useMemo(() => {
    return { authService, accessToken, userId };
  }, [authService, accessToken, userId]);

  return <AuthContext.Provider value={authBag}>{children}</AuthContext.Provider>;
};
