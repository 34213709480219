import { Button, ButtonProps } from '@audi/audi-ui-react';
import { createMediaQuery, getEasing } from '@oneaudi/narown-utils-ui';
import styled, { AnyStyledComponent, Keyframes, css, keyframes } from 'styled-components';

import {
  ANIMATION_DURATION,
  AnimatedContainerProps,
  AnimationStates,
  OpacityFadeIn,
  OpacityFadeOut,
} from '../../styled/animation.styles';

export const AddVehicleButtonEnter = keyframes`
  0% { left: -235px; }
  100% { left: 0px; }
`;

export const AddVehicleButtonLeaving = keyframes`
  0% { left: 0px; }
  100% { left: -235px; }
`;

export const AddVehicleButtonEnterTablet = keyframes`
  0% { left: -51%; }
  100% { left: 0%; }
`;

export const AddVehicleButtonLeavingTablet = keyframes`
  0% { left: 0%; }
  100% { left: -51%; }
`;

export const AddVehicleButtonEnterVertical = keyframes`
  0% { top: -66px; }
  100% { top: 0px; }
`;

export const AddVehicleButtonLeavingVertical = keyframes`
  0% { top: 0px; }
  100% { top: -66px; }
`;

const ButtonLayoutEnterVertical = keyframes`
  from { height: 58px; }
  to { height: 124px; }
`;

const ButtonLayoutLeavingVertical = keyframes`
  from { height: 124px; }
  to { height: 58px; }
`;

interface AnimatedElementProps {
  state: AnimationStates;
  onAnimationEnd?: () => void;
}

interface AnimatedButtonProps extends AnimatedElementProps, ButtonProps {}

const buildAnimation = (animation: Keyframes) => {
  return css`
    animation: ${animation} ${ANIMATION_DURATION} ${getEasing()};
  `;
};

const buildAnimationObject = (enterAnimation: Keyframes, leaveAnimation: Keyframes) => {
  return {
    [AnimationStates.ENTERING]: buildAnimation(enterAnimation),
    [AnimationStates.LEAVING]: buildAnimation(leaveAnimation),
    [AnimationStates.HIDDEN]: '',
    [AnimationStates.IDLE]: '',
  };
};

const LayoutAnimations = buildAnimationObject(
  ButtonLayoutEnterVertical,
  ButtonLayoutLeavingVertical,
);

const ButtonOpacityAnimations = buildAnimationObject(OpacityFadeIn, OpacityFadeOut);

const AddVehicleButtonContainerAnimations = {
  xs: buildAnimationObject(AddVehicleButtonEnterVertical, AddVehicleButtonLeavingVertical),
  m: buildAnimationObject(AddVehicleButtonEnterTablet, AddVehicleButtonLeavingTablet),
  l: buildAnimationObject(AddVehicleButtonEnter, AddVehicleButtonLeaving),
};

export const ButtonLayout = styled.div<AnimatedElementProps>`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  width: auto;
  z-index: 1;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    ${(props) => LayoutAnimations[props.state]}
    flex-direction: column;
  }
`;

export const AddVehicleButton = styled(Button as AnyStyledComponent)<AnimatedButtonProps>`
  > span {
    ${(props) => ButtonOpacityAnimations[(props as AnimatedButtonProps).state]}
    max-height: 24px;
  }

  ${createMediaQuery({
    width: {
      l: '250px',
    },
  })};
`;

export const AddVehicleButtonContainer = styled.div<AnimatedContainerProps>`
  ${(props) => AddVehicleButtonContainerAnimations.xs[props.state]}
  position: relative;

  @media (min-width: 769px) {
    ${(props) => AddVehicleButtonContainerAnimations.m[props.state]}
    width: 110%;
  }

  @media (min-width: 1024px) {
    ${(props) => AddVehicleButtonContainerAnimations.l[props.state]}
  }
`;

export const BackButton = styled(Button as AnyStyledComponent)<AnimatedButtonProps>`
  ${(props) => ButtonOpacityAnimations[(props as AnimatedButtonProps).state]}
  margin-bottom: 8px;

  @media (min-width: 769px) {
    margin-bottom: 0px;
    margin-right: 8px;
  }

  ${createMediaQuery({
    width: {
      l: '354px',
    },
  })};
`;
