import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { Maybe } from '../../types';
import { LanguageCountryKey, RedirectMap } from './redirectMap';

export const getRedirectPath = (
  redirectMap: RedirectMap,
  localeService: Maybe<GfaLocaleServiceV1>,
): string => {
  const mapKey = `${localeService?.language}-${localeService?.countryCode}` as LanguageCountryKey;
  // eslint-disable-next-line security/detect-object-injection
  if (redirectMap[mapKey]) {
    // eslint-disable-next-line security/detect-object-injection
    return redirectMap[mapKey];
  }

  return '#';
};

export const getOverviewPath = (vin: string): string => {
  const encodedVin = btoa(`Vehicle:${vin}`);
  return `overview.html?vehicleId=${encodedVin}`;
};
