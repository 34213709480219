import React, { useEffect, useRef, useState } from 'react';
import { AudiPlatformProvider } from '@audi/audi-ui-react';
import { Trans, useTranslation } from 'react-i18next';

import { ModalErrorTypes, ModalErrorContent } from './shared';
import { ErrorModal } from '../ErrorModal';
import { ModalRoot } from '../ErrorModal/styles';

type VinFormErrorProps = {
  isError: boolean;
  modalErrorType: ModalErrorTypes;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
};

export const VinFormError: React.FC<VinFormErrorProps> = ({
  isError,
  modalErrorType,
  onPrimaryAction,
  onSecondaryAction,
}) => {
  const modalRootRef = useRef<HTMLDivElement>(null);
  const [modalRootElement, setModalRootElement] = useState(modalRootRef.current);
  const { t } = useTranslation();
  // eslint-disable-next-line security/detect-object-injection
  const modalContent = ModalErrorContent[modalErrorType];

  useEffect(() => {
    /* istanbul ignore next: difficult to test because of the lifecycle of a ref */
    if (modalRootRef.current) {
      setModalRootElement(modalRootRef.current);
    }
  }, [modalRootRef, setModalRootElement]);
  return (
    <>
      <AudiPlatformProvider>
        <ErrorModal
          header={t(modalContent.headerKey)}
          isOpen={isError}
          labelPrimary={t(modalContent.primaryLabelKey)}
          labelSecondary={t(modalContent.secondaryLabelKey)}
          modalRoot={modalRootElement}
          onClose={onPrimaryAction}
          onPrimaryAction={onPrimaryAction}
          onSecondaryAction={onSecondaryAction}
        >
          <Trans i18nKey={modalContent.messageKey} />
        </ErrorModal>
      </AudiPlatformProvider>
      <ModalRoot ref={modalRootRef} />
    </>
  );
};
