import { createContext } from 'react';
import { defaultConfig, type AddVehicleEndPoints } from '../../config';
import { type LazyDataFetchOptions } from '../../types/shared';
import { fetchOptions } from './fetchOptions';

export interface LazyDataFetchContextContent {
  endPoints: AddVehicleEndPoints;
  fetchOptions: LazyDataFetchOptions;
}

export const LazyDataFetchContext = createContext<LazyDataFetchContextContent>({
  endPoints: defaultConfig.endpoints.us,
  fetchOptions: fetchOptions().us,
});
