import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimationStates } from '../../styled/animation.styles';

import { CardTitle, Container } from './styles';
import { SummaryContent } from './SummaryContent';

interface CardHeaderProps {
  heightReference: number;
  isEditingVin: boolean;
  shouldDisplay: boolean;
}

export const CardHeader: React.FC<CardHeaderProps> = ({
  heightReference,
  isEditingVin,
  shouldDisplay,
}) => {
  const [isEditing, setIsEditing] = useState(isEditingVin);
  const [animationState, setAnimationState] = useState(AnimationStates.IDLE);
  const { t } = useTranslation();

  const handleStateSwitch = (value: boolean) => {
    setIsEditing(value);
    setAnimationState(value ? AnimationStates.LEAVING : AnimationStates.ENTERING);
  };

  const handleContainerAnimationEnd = () => {
    if (animationState !== AnimationStates.IDLE) {
      setAnimationState(AnimationStates.IDLE);
    }
  };

  if (shouldDisplay) {
    return (
      <Container
        heighReference={heightReference}
        isEditingVin={isEditing}
        state={animationState}
        onAnimationEnd={handleContainerAnimationEnd}
      >
        <CardTitle>{t('addVehicle.addYourVehicle')}</CardTitle>
        <SummaryContent isEditing={isEditingVin} onStateSwitch={handleStateSwitch} />
      </Container>
    );
  }

  return null;
};
