import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { withAudiDesignSystemDark } from '../../../componentWrappers';
import { UserInfo } from '../../../types';

const _HeaderLabel: React.FC<{ userInfo: UserInfo | null }> = ({ userInfo }) => {
  const { t } = useTranslation();
  const name = userInfo?.nickname || userInfo?.firstName;
  const translationKey = name ? 'addVehicle.welcome' : 'addVehicle.welcomeBack';
  const welcomeMessage = t(translationKey, { name });
  return <Text variant="order2">{welcomeMessage}</Text>;
};

export const HeaderLabel = withAudiDesignSystemDark(_HeaderLabel);
