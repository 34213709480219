import { Layout } from '@audi/audi-ui-react';
import { isBreakpointL } from '@oneaudi/narown-utils-ui';
import React, { useRef, useState } from 'react';

import { withAudiDesignSystemLight } from '../../componentWrappers';
import { useConfigurationContext, useCurrentBreakpoint } from '../../hooks';
import backgroundL from '../../images/myaudi-overview-noVehicle-1024.jpg';
import backgroundXl from '../../images/myaudi-overview-noVehicle-1440.jpg';
import backgroundXxl from '../../images/myaudi-overview-noVehicle-1920.jpg';
import backgroundS from '../../images/myaudi-overview-noVehicle-375.jpg';
import backgroundM from '../../images/myaudi-overview-noVehicle-768.jpg';
import { UserInfo, VinDetails } from '../../types';
import { resolveImage } from '../../utils';
import { CardHeader } from '../CardHeader';
import ParallaxBackground from '../ParallaxBackground';
import { ContentContainer, GradientContainer } from '../ParallaxBackground/styles';
import { VehicleImage } from '../VehicleImage';
import { VinForm } from '../VinForm';
import { HeaderLabel } from './HeaderLabel';
import { Card, CardLayout, Content } from './styles';

const imageMap = {
  xs: backgroundS,
  s: backgroundS,
  m: backgroundM,
  l: backgroundL,
  xl: backgroundXl,
  xxl: backgroundXxl,
};

interface AddVehicleProps {
  isError?: boolean;
  userInfo: UserInfo;
  onRedirect: (v: string) => void;
}

export const _AddVehicle: React.FC<AddVehicleProps> = ({ isError, userInfo, onRedirect }) => {
  const breakpoint = useCurrentBreakpoint();
  const [isEditingVin, setIsEditingVin] = useState(true);
  const [error, setError] = useState(isError);
  const [vehicleDetails, setVehicleDetails] = useState<VinDetails>();
  const [imageSource, setImageSource] = useState('');
  const [hasImage, setHasImage] = useState(false);
  const [heightReference, setHeightReference] = useState(0);
  const contentContainerRef = useRef(null);
  const { baseUrl } = useConfigurationContext();

  // eslint-disable-next-line security/detect-object-injection
  const backgroundImage = imageMap[breakpoint];

  const handleImageLoaded = (src: string, imageLoaded: boolean) => {
    setImageSource(src);
    setHasImage(imageLoaded);
  };

  return (
    <ParallaxBackground
      image={resolveImage(backgroundImage, baseUrl)}
      contentContainerRef={contentContainerRef}
      shouldAnimate={!error}
    >
      <GradientContainer>
        <ContentContainer ref={contentContainerRef}>
          <Content>
            <HeaderLabel userInfo={userInfo} />
            <Card>
              <CardHeader
                heightReference={heightReference}
                isEditingVin={isEditingVin}
                shouldDisplay={isBreakpointL(breakpoint)}
              />
              <CardLayout>
                <Layout align="start" direction="column" justify="end">
                  <CardHeader
                    heightReference={heightReference}
                    isEditingVin={isEditingVin}
                    shouldDisplay={!isBreakpointL(breakpoint)}
                  />
                  <VinForm
                    isError={isError || false}
                    isEditingVin={isEditingVin}
                    heightReference={heightReference}
                    updateHeightReference={setHeightReference}
                    onUpdateIsError={(value: boolean) => setError(value)}
                    onGetVehicleDetails={setVehicleDetails}
                    onImageLoaded={handleImageLoaded}
                    onRedirect={onRedirect}
                    onUpdateEditVin={(value: boolean) => setIsEditingVin(value)}
                  />
                </Layout>
                <VehicleImage
                  isEditingVin={isEditingVin}
                  hasImage={hasImage}
                  imageSource={imageSource}
                  vehicleModel={vehicleDetails?.model}
                  vehicleYear={vehicleDetails?.year}
                />
              </CardLayout>
            </Card>
          </Content>
        </ContentContainer>
      </GradientContainer>
    </ParallaxBackground>
  );
};

export const AddVehicle = withAudiDesignSystemLight(_AddVehicle);
