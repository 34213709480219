import { keyframes } from 'styled-components';
import {
  AnimationStates,
  ANIMATION_HALF_DURATION,
  buildAnimation,
} from '../../styled/animation.styles';

const buildTopKeyFrame = (from: number, to: number) => {
  return keyframes`
    from { top: ${from}px};
    to { top: ${to}px}
  `;
};

export enum AnimationBreakpoints {
  L,
  XL,
  XXL,
}

/**
 * Values are calculated from the total height of EnterVin (including margins)
 * minus Vin Details bottom Margin. It can be calculated at runtime but
 * it can become really complex to calculate this on runtime due to the amount
 * of required props or object prop that needs to be passed for this
 */
const HeightConstants = {
  [AnimationBreakpoints.L]: 109,
  [AnimationBreakpoints.XL]: 117,
  [AnimationBreakpoints.XXL]: 137,
};

/**
 * values are calculated from the top padding of the header plus
 * the bottom margin difference between the header contents.
 * It can be calculated at runtime but it can become really complex
 * to calculate this on runtime due to the amount of ref values needed
 * to do this.
 */
const PaddingConstants = {
  [AnimationBreakpoints.L]: 85,
  [AnimationBreakpoints.XL]: 85,
  [AnimationBreakpoints.XXL]: 65,
};

export const getCardHeaderTopAnimation = (
  heightReference: number,
  breakpoint: AnimationBreakpoints,
) => {
  // eslint-disable-next-line security/detect-object-injection
  const padding = heightReference - HeightConstants[breakpoint];
  return {
    [AnimationStates.ENTERING]: buildAnimation(
      ANIMATION_HALF_DURATION,
      buildTopKeyFrame(padding, 0),
    ),
    [AnimationStates.LEAVING]: buildAnimation(
      ANIMATION_HALF_DURATION,
      buildTopKeyFrame(-padding, 0),
    ),
    [AnimationStates.HIDDEN]: '',
    [AnimationStates.IDLE]: '',
  };
};

export const getPaddingTop = (heightReference: number, breakpoint: AnimationBreakpoints) => {
  // eslint-disable-next-line security/detect-object-injection
  return `${PaddingConstants[breakpoint] - heightReference + HeightConstants[breakpoint]}px`;
};
