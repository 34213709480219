import React, { PropsWithChildren, useMemo } from 'react';
import { useConfigurationContext, useLocaleService } from '../../hooks';
import { LazyDataFetchContext } from './LazyDataFetchContext';
import { fetchOptions } from './fetchOptions';

export const LazyDataFetchContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { lambdaEndPoints } = useConfigurationContext();
  const { localeService } = useLocaleService();

  const values = useMemo(() => {
    if (localeService?.countryCode === 'CA') {
      return {
        endPoints: lambdaEndPoints.ca,
        fetchOptions: fetchOptions(localeService.language).ca,
      };
    }

    return {
      endPoints: lambdaEndPoints.us,
      fetchOptions: fetchOptions().us,
    };
  }, [lambdaEndPoints, localeService]);

  return <LazyDataFetchContext.Provider value={values}>{children}</LazyDataFetchContext.Provider>;
};
