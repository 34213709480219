import { Theme } from '@audi/audi-ui-react';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

export type ThemeProps = { theme: Theme };

export type Placement =
  | 'bottom'
  | 'bottom left'
  | 'bottom right'
  | 'top'
  | 'top left'
  | 'top right'
  | 'left'
  | 'left top'
  | 'left bottom'
  | 'right'
  | 'right top'
  | 'right bottom';

export enum ModalActions {
  CLOSE,
  ENTER_NEW_VIN,
  NOTHING,
  REDIRECT_TO_OVERVIEW,
  REDIRECT_TO_SUPPORT,
}

export interface VinDetails {
  vin: string;
  year: number;
  model: string;
  trim: string;
}

export type VinDetailsResponse = {
  data: VinDetails;
};

export interface DetailsDataResponse {
  getVinDetails: (a: string) => void;
  vinDetailsData?: VinDetails | null;
  error?: Error | null;
  loading: boolean;
}

export interface UsAddUserVehicle {
  id: string;
}

export type AddUserVehicleType = UsAddUserVehicle | { addUserVehicle: string } | null;

export interface AddUserVehicleData {
  addUserVehicle: (vin: string) => void;
  loading: boolean;
}

export type UseAddUserVehicle = (
  accessToken: Maybe<string>,
  onCompleted: () => void,
  onError: (error: Error) => void,
) => AddUserVehicleData;

export type UseVinDetails = (
  accessToken: Maybe<string>,
  onCompleted: (data: VinDetails) => void,
  onError: (error: Error) => void,
) => DetailsDataResponse;

export type Headers = { [key: string]: string };

export type FetchOptions = {
  body?: string;
  headers?: Headers;
  method?: 'GET' | 'POST';
};

export type UserInfo = {
  firstName: Maybe<string>;
  nickname: Maybe<string>;
};

export type UserInfoResponse = {
  data: UserInfo;
};

export type LazyDataFetchOptions = {
  addVehicle: (body: string) => {
    body: string;
    method: 'POST';
    headers?: Headers;
  };
  vinDetails: {
    method: 'GET';
    headers?: Headers;
  };
};

export type LocalizedDataFetchOptions = {
  [key: string]: LazyDataFetchOptions;
};
