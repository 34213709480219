import React, { useEffect, useState } from 'react';
import { AnimationStates } from '../../styled/animation.styles';
import { ConfirmVin, ConfirmVinProps } from '../ConfirmVin';
import { SubmitVin, SubmitVinProps } from '../SubmitVin';

interface CtaHandlerProps extends ConfirmVinProps, SubmitVinProps {
  isEditingVin: boolean;
}

export const CtaHandler: React.FC<CtaHandlerProps> = ({
  disabled,
  isEditingVin,
  loading,
  vin: vinValue,
  onSubmitVin,
  onValidateVin,
  onAddVin,
  onEditVin,
}) => {
  const [isEditing, setIsEditing] = useState(isEditingVin);
  const [animationState, setAnimationState] = useState(AnimationStates.IDLE);
  useEffect(() => {
    if (!isEditingVin) {
      setIsEditing(isEditingVin);
    }
    setAnimationState(isEditingVin ? AnimationStates.LEAVING : AnimationStates.ENTERING);
  }, [isEditingVin]);
  const handleConfirmVinAnimationEnd = () => {
    if (!isEditing && animationState === AnimationStates.LEAVING) {
      setIsEditing(isEditingVin);
    }
    setAnimationState(AnimationStates.IDLE);
  };
  return isEditing ? (
    <SubmitVin
      disabled={disabled}
      loading={loading}
      vin={vinValue}
      onSubmitVin={onSubmitVin}
      onValidateVin={onValidateVin}
    />
  ) : (
    <ConfirmVin
      loading={loading}
      state={animationState}
      vin={vinValue}
      onAddVin={onAddVin}
      onEditVin={onEditVin}
      onAnimationEnd={handleConfirmVinAnimationEnd}
    />
  );
};
