import { Button } from '@audi/audi-ui-react';
import { createMediaQuery } from '@oneaudi/narown-utils-ui';
import styled, { AnyStyledComponent } from 'styled-components';

export const SubmitVinButton = styled(Button as AnyStyledComponent)`
  > span {
    max-height: 24px;
  }

  ${createMediaQuery({
    width: {
      l: '250px',
    },
  })};
`;
