import { GraphQLClient } from 'graphql-request';
import { US_IMAGE_INFO } from './queries';
import { UsMediaInfoResponse } from './types';
import { useConfigurationContext } from '../useConfigurationContext';

export const useLazyImage = (
  onCompleted: (result: UsMediaInfoResponse) => void,
  onError: (error: string) => void,
) => {
  const { narGateway } = useConfigurationContext();

  const lazyImage = (vin: string, loadFunction: (src: string) => void) => {
    const narClient = new GraphQLClient(narGateway, {
      headers: { 'Content-Type': 'application/json' },
    });
    narClient
      .request<UsMediaInfoResponse>(US_IMAGE_INFO, {
        vin,
      })
      .then((data) => {
        loadFunction(data.getMediaInfoByVin.mediaImages[0].url);
        onCompleted(data);
      })
      .catch((e: Error) => {
        onError(e.message);
      });
  };

  return { lazyImage };
};
