import { createMediaQuery, injectTypography } from '@oneaudi/narown-utils-ui';
import styled, { css } from 'styled-components';
import { AnimatedContainer, AnimationStates } from '../../styled/animation.styles';
import { BREAKPOINTS_PIXEL } from '../../styled/sizes';
import { AnimationBreakpoints, getCardHeaderTopAnimation, getPaddingTop } from './animation.styles';

const getCardPaddingTops = (heightReference: number) => {
  return {
    l: getPaddingTop(heightReference, AnimationBreakpoints.L),
    xl: getPaddingTop(heightReference, AnimationBreakpoints.XL),
    xxl: getPaddingTop(heightReference, AnimationBreakpoints.XXL),
  };
};

const CardPaddingTopsBig = {
  l: '85px',
  xl: '73px',
  xxl: '53px',
};

const buildContainerMediaQuery = (
  breakpoint: string,
  animations: {
    [key in AnimationStates]: ReturnType<typeof css> | string;
  },
) => {
  return css<{ state: AnimationStates }>`
    @media (min-width: ${breakpoint}) {
      ${(props) => {
        return animations[props.state];
      }}
    }
  `;
};

export const CardTitle = styled.div`
  ${injectTypography('order4')}
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 24px;

  ${createMediaQuery({
    'font-size': {
      xxl: '18px',
    },

    'margin-top': {
      m: '40px',
      l: '0px',
      xl: '28px',
    },
  })}
`;

export const CardEnterVinSummary = styled(AnimatedContainer)`
  ${injectTypography('copy2')}
  margin-bottom: 36px;

  ${createMediaQuery({
    'font-size': {
      l: '16px',
    },
    'margin-bottom': {
      m: '36px',
      xl: '44px',
    },
  })}
`;

export const CardConfirmVinSummary = styled(CardEnterVinSummary)`
  ${createMediaQuery({
    'margin-bottom': {
      m: '36px',
      xl: '32px',
    },
  })}
`;

export interface CardHeaderAnimationProps {
  heighReference: number;
  isEditingVin: boolean;
  state: AnimationStates;
  onAnimationEnd: () => void;
}

export const Container = styled.div<CardHeaderAnimationProps>`
  position: relative;

  ${(props) =>
    createMediaQuery({
      'padding-top': props.isEditingVin
        ? CardPaddingTopsBig
        : getCardPaddingTops(props.heighReference),
    })}

  ${(props) =>
    buildContainerMediaQuery(
      BREAKPOINTS_PIXEL.l,
      getCardHeaderTopAnimation(props.heighReference, AnimationBreakpoints.L),
    )}

  ${(props) =>
    buildContainerMediaQuery(
      BREAKPOINTS_PIXEL.xl,
      getCardHeaderTopAnimation(props.heighReference, AnimationBreakpoints.XL),
    )}

  ${(props) =>
    buildContainerMediaQuery(
      BREAKPOINTS_PIXEL.xxl,
      getCardHeaderTopAnimation(props.heighReference, AnimationBreakpoints.XXL),
    )}
`;
