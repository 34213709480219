export const BREAKPOINTS_RAW = {
  xs: 0,
  s: 375,
  m: 768,
  l: 1024,
  xl: 1440,
  xxl: 1920,
};

export const BREAKPOINTS_PIXEL = {
  xs: `${BREAKPOINTS_RAW.xs}px`,
  s: `${BREAKPOINTS_RAW.s}px`,
  m: `${BREAKPOINTS_RAW.m}px`,
  l: `${BREAKPOINTS_RAW.l}px`,
  xl: `${BREAKPOINTS_RAW.xl}px`,
  xxl: `${BREAKPOINTS_RAW.xxl}px`,
};

export enum BreakPointSize {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}
