import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import get from 'lodash.get';
import React from 'react';
import { useAuthContext, useConfigurationContext, useFetch } from '../../hooks';
import { UserInfo, UserInfoResponse } from '../../types';

interface WithUserInfoProps {
  userInfo: UserInfo;
}

export const withUserInfo =
  (localeService: GfaLocaleServiceV1) =>
  <T extends WithUserInfoProps>(Component: React.FC<T>) =>
  (props: React.PropsWithChildren<Omit<T, 'userInfo'>>) => {
    const { accessToken } = useAuthContext();
    const { lambdaBaseUrl, lambdaEndPoints } = useConfigurationContext();
    const endPoints = get(
      lambdaEndPoints,
      localeService.countryCode.toLowerCase(),
    ) as (typeof lambdaEndPoints)[keyof typeof lambdaEndPoints];
    const options = {
      headers: {
        'Accept-Language': `${localeService.language}-${localeService.countryCode}`,
        'market-id': `${localeService.countryCode}`,
      },
    };
    const url = `${lambdaBaseUrl}${endPoints.user}`;
    const { data: user } = useFetch<UserInfoResponse>(accessToken as string, url, options);

    return <Component {...(props as T)} userInfo={user?.data} />;
  };
