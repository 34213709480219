import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfigurationContext } from '../../hooks';
import backgroundNoVehicle from '../../images/audi_default_vehicle_w800.png';
import { AnimationStates } from '../../styled/animation.styles';
import { resolveImage } from '../../utils';
import { AnimatedImageContainer } from './AnimatedImageContainer';

interface VehicleImageProps {
  isEditingVin: boolean;
  hasImage: boolean;
  imageSource?: string;
  vehicleModel?: string;
  vehicleYear?: number;
}

export const VehicleImage: React.FC<VehicleImageProps> = ({
  isEditingVin,
  hasImage,
  imageSource,
  vehicleModel,
  vehicleYear,
}) => {
  const { t } = useTranslation();
  const { baseUrl } = useConfigurationContext();
  const [defaultImageState, setDefaultImageState] = useState(AnimationStates.IDLE);
  const [vehicleImageState, setVehicleState] = useState(AnimationStates.HIDDEN);

  useEffect(() => {
    if (isEditingVin) {
      if (vehicleImageState === AnimationStates.IDLE) {
        setVehicleState(AnimationStates.LEAVING);
      }
    } else if (hasImage) {
      setDefaultImageState(AnimationStates.LEAVING);
    }
  }, [isEditingVin, hasImage]);

  const handleDefaultImageAnimationEnd = () => {
    if (isEditingVin) {
      setDefaultImageState(AnimationStates.IDLE);
    } else {
      setDefaultImageState(AnimationStates.HIDDEN);
      setVehicleState(AnimationStates.ENTERING);
    }
  };

  const handleVehicleImageAnimationEnd = () => {
    if (isEditingVin) {
      setDefaultImageState(AnimationStates.ENTERING);
      setVehicleState(AnimationStates.HIDDEN);
    } else {
      setVehicleState(AnimationStates.IDLE);
    }
  };

  const vehiclePhotoAlt = t('addVehicle.vehiclePhotoAlt', {
    model: vehicleModel,
    year: vehicleYear,
  });

  return (
    <>
      <AnimatedImageContainer
        altText={t('addVehicle.genericVehicleAlt')}
        source={resolveImage(backgroundNoVehicle, baseUrl)}
        state={defaultImageState}
        onAnimationEnd={handleDefaultImageAnimationEnd}
      />
      <AnimatedImageContainer
        altText={vehiclePhotoAlt}
        source={imageSource}
        state={vehicleImageState}
        onAnimationEnd={handleVehicleImageAnimationEnd}
      />
    </>
  );
};
