/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import { AuthServiceV2 } from '@oneaudi/audi-auth-service';
import { redirectTo } from '@oneaudi/narown-utils-ui';
import { App, ProviderProps as AppProps } from '@oneaudi/oneaudi-os-react';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import compose from 'lodash.flowright';
import React from 'react';
import { I18nextProvider } from 'react-i18next';

import i18n from '../i18n';
import { withErrorBoundary, withFocusReset, withUserInfo } from './componentWrappers';
import { AddVehicle } from './components';
import { type LambdaEndPoints } from './config';
import {
  AuthContextProvider,
  ConfigurationContextProvider,
  LazyDataFetchContextProvider,
  LocaleContextProvider,
} from './contexts';

interface FeatureAppProps extends Partial<AppProps> {
  authService: AuthServiceV2;
  baseUrl: string;
  lambdaBaseUrl: string;
  lambdaEndPoints: LambdaEndPoints;
  localeService: GfaLocaleServiceV1;
  narGateway: string;
}

const _FeatureApp: React.FC<FeatureAppProps> = ({
  authService,
  baseUrl,
  lambdaBaseUrl,
  lambdaEndPoints,
  localeService,
  narGateway,
  ...inherited
}: FeatureAppProps) => {
  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };
  const AddVehicleWithUserInfo = withUserInfo(localeService)(AddVehicle);

  return (
    <I18nextProvider i18n={i18n}>
      <App config={appConfig} app={app} {...inherited}>
        <AuthContextProvider authService={authService}>
          <ConfigurationContextProvider
            baseUrl={baseUrl}
            lambdaBaseUrl={lambdaBaseUrl}
            lambdaEndPoints={lambdaEndPoints}
            narGateway={narGateway}
          >
            <LocaleContextProvider localeService={localeService}>
              <LazyDataFetchContextProvider>
                <AddVehicleWithUserInfo onRedirect={redirectTo} />
              </LazyDataFetchContextProvider>
            </LocaleContextProvider>
          </ConfigurationContextProvider>
        </AuthContextProvider>
      </App>
    </I18nextProvider>
  );
};

export default compose(withErrorBoundary, withFocusReset)(_FeatureApp) as React.FC<FeatureAppProps>;
