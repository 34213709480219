import fetch from 'isomorphic-fetch';
import { useState } from 'react';
import { FetchOptions, isError } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const uniqid: (prefix?: string, suffix?: string) => string = require('uniqid');

export const useLazyFetch = <T>(
  onCompleted: (result: T) => void,
  onError: (error: string) => void,
) => {
  const [loading, setLoading] = useState(false);

  const lazyFetch = (accessToken: string, url: string, options: FetchOptions) => {
    if (accessToken) {
      setLoading(true);
      fetch(url, {
        body: options.body,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
          'request-id': uniqid('add-vehicle-'),
        },
        method: options.method || 'GET',
      })
        .then((response) => {
          if (response.ok) {
            return response.status === 204 ? Promise.resolve({}) : response.json();
          }
          throw new Error(response.status.toString());
        })
        .then((responseData: T | Error) => {
          if (isError(responseData)) {
            onError(responseData.message);
          } else {
            onCompleted(responseData);
          }
        })
        .catch((e: Error) => {
          onError(e.message ? e.message : 'unexpected error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return {
    lazyFetch,
    loading,
  };
};
