import {
  audiDarkTheme,
  audiLightTheme,
  AudiPlatformProvider,
  TokenizedTheme,
} from '@audi/audi-ui-react';
import React from 'react';

const withAudiDesignSystem =
  (theme: TokenizedTheme) =>
  <T extends object>(Component: React.FC<T>): React.FC<T> => {
    const WithAudiDesignSystem: React.FC<T> = ({ ...props }) => (
      <AudiPlatformProvider theme={theme}>
        <Component {...props} />
      </AudiPlatformProvider>
    );

    WithAudiDesignSystem.displayName = `WithAudiDesignSystem(${
      /* istanbul ignore next */
      Component.displayName || Component.name || 'Component'
    })`;

    return WithAudiDesignSystem;
  };

export const withAudiDesignSystemDark = withAudiDesignSystem(audiDarkTheme);
export const withAudiDesignSystemLight = withAudiDesignSystem(audiLightTheme);
