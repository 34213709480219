import { createMediaQuery } from '@oneaudi/narown-utils-ui';
import styled from 'styled-components';
import { AnimatedContainerProps, AnimatedFade } from '../../styled/animation.styles';

const minImageHeights = {
  m: '305px',
  l: '200px',
  xl: '307px',
  xxl: '343px',
};

export const ImageContainer = styled.img<AnimatedContainerProps>`
  ${AnimatedFade}
  box-sizing: border-box;
  height: 130px;
  margin: 0 auto -24px;
  max-width: 273px;
  min-height: 130px;
  object-fit: cover;
  width: 100%;
  z-index: 0;

  ${createMediaQuery({
    height: minImageHeights,
    'max-width': {
      m: '625px',
      l: '425px',
      xl: '645px',
      xxl: '720px',
    },
    'margin-bottom': {
      m: '-48px',
      l: '-56px',
    },
    'min-height': minImageHeights,
    position: {
      l: 'absolute',
      xl: 'default',
    },
    top: {
      l: '0%',
      xl: '25%',
    },
    width: {
      l: '525px',
      xl: '645px',
      xxl: '720px',
    },
    right: {
      l: '0',
    },
  })}
`;
