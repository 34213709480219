import { Popover, PopoverBody, Text } from '@audi/audi-ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurationContext } from '../../hooks';

import imageFindVin from '../../images/audi-find-vin-example.png';
import { resolveImage } from '../../utils';
import { PopoverBodyContainer } from './styles';

export const FindVinPopover: React.FC = () => {
  const { t } = useTranslation();
  const { baseUrl } = useConfigurationContext();
  return (
    <Popover aria-label="Popover where to find VIN">
      <PopoverBody>
        <PopoverBodyContainer>
          <img
            data-testid="popover-image"
            alt="Example of where VIN is on vehicle"
            src={resolveImage(imageFindVin, baseUrl)}
          />
          <Text>{t('addVehicle.whereVinDesc')}</Text>
        </PopoverBodyContainer>
      </PopoverBody>
    </Popover>
  );
};
