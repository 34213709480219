import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { AddVehicleError } from '../../components/AddVehicleError';

export const withErrorBoundary = <T extends object>(Component: React.FC<T>): React.FC<T> => {
  const WithErrorBoundary: React.FC<T> = ({ ...props }) => (
    <ErrorBoundary FallbackComponent={AddVehicleError}>
      <Component {...props} />
    </ErrorBoundary>
  );

  WithErrorBoundary.displayName = `WithErrorBoundary(${
    /* istanbul ignore next */
    Component.displayName || Component.name || 'Component'
  })`;

  return WithErrorBoundary;
};
