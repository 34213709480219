export enum ValidationMessageKey {
  VALIDATION_ERROR_KEY = 'addVehicleError.vinError',
  INVALID_VIN_KEY = 'addVehicleError.invalidVin',
}

export enum ModalErrorTypes {
  GENERIC_ERROR,
}

export const ModalErrorContent = Object.freeze({
  [ModalErrorTypes.GENERIC_ERROR]: {
    headerKey: 'addVehicleError.commonMessage.title',
    messageKey: 'addVehicleError.commonMessage.message',
    primaryLabelKey: 'addVehicleError.close',
    secondaryLabelKey: 'addVehicleError.report',
  },
});

export interface VinFormErrorProps {
  modalErrorType: ModalErrorTypes;
  primaryAction: () => void;
  secondaryAction: () => void;
}
