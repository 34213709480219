import { ContentFragment } from '@oneaudi/falcon-tools';
import merge from 'lodash.merge';

const LAMBDA_BASE_URL_DEV = 'https://fa-nar-add-vehicle.api.dev.collab.apps.one.audi/api/';
const NAR_GATEWAY = 'https://qa-api.audiusa.com/graphql';
const US_USER_ENDPOINT = 'us-user';
const US_VEHICLE_ENDPOINT = 'us-vehicle';
const US_ADD_VEHICLE_ENDPOINT = 'us-add-vehicle';
const CA_USER_ENDPOINT = 'ca-user';
const CA_VEHICLE_ENDPOINT = 'ca-vehicle';
const CA_ADD_VEHICLE_ENDPOINT = 'ca-add-vehicle';

export type AppConfig = {
  lambdaBaseUrl: string;
  narGateway: string;
  endpoints: {
    us: {
      addVehicle: string;
      user: string;
      vehicle: string;
    };
    ca: {
      user: string;
      vehicle: string;
      addVehicle: string;
    };
  };
};

export type FalconConfig = {
  __type: 'aem-headless';
  config: Partial<ContentFragment> & {
    fields: {
      lambdaBaseUrl: string;
      narGateway: string;
      endpoints_us_addVehicle: string;
      endpoints_us_user: string;
      endpoints_us_vehicle: string;
      endpoints_ca_addVehicle: string;
      endpoints_ca_user: string;
      endpoints_ca_vehicle: string;
    };
  };
};

export type LambdaEndPoints = typeof defaultConfig.endpoints;
export type AddVehicleEndPoints = typeof defaultConfig.endpoints.us;

export const defaultConfig: AppConfig = {
  lambdaBaseUrl: LAMBDA_BASE_URL_DEV,
  narGateway: NAR_GATEWAY,
  endpoints: {
    us: {
      addVehicle: US_ADD_VEHICLE_ENDPOINT,
      user: US_USER_ENDPOINT,
      vehicle: US_VEHICLE_ENDPOINT,
    },
    ca: {
      user: CA_USER_ENDPOINT,
      vehicle: CA_VEHICLE_ENDPOINT,
      addVehicle: CA_ADD_VEHICLE_ENDPOINT,
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFalconConfig = (config: any): config is FalconConfig => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return config?.__type === 'aem-headless';
};

const normalizeConfig = (configToNormalize: FalconConfig | Partial<AppConfig>): AppConfig => {
  if (isFalconConfig(configToNormalize)) {
    const normalizedConfig: AppConfig = {
      lambdaBaseUrl: configToNormalize.config?.fields.lambdaBaseUrl,
      narGateway: configToNormalize.config?.fields.narGateway || NAR_GATEWAY,
      endpoints: {
        us: {
          addVehicle:
            configToNormalize.config?.fields.endpoints_us_addVehicle || US_ADD_VEHICLE_ENDPOINT,
          user: configToNormalize.config?.fields.endpoints_us_user || US_USER_ENDPOINT,
          vehicle: configToNormalize.config?.fields.endpoints_us_vehicle || US_VEHICLE_ENDPOINT,
        },
        ca: {
          user: configToNormalize.config?.fields.endpoints_ca_user || CA_USER_ENDPOINT,
          vehicle: configToNormalize.config?.fields.endpoints_ca_vehicle || CA_VEHICLE_ENDPOINT,
          addVehicle:
            configToNormalize.config?.fields.endpoints_ca_addVehicle || CA_ADD_VEHICLE_ENDPOINT,
        },
      },
    };
    return normalizedConfig;
  }
  return configToNormalize as AppConfig;
};

export const mergeConfig = (config: Partial<AppConfig>) =>
  merge({}, defaultConfig, normalizeConfig(config));

export const localConfig = mergeConfig({
  lambdaBaseUrl: 'http://localhost:8000/api/',
  narGateway: 'https://qa.aoaaudinagateway.svc.audiusa.io/graphql',
});
