import React, { PropsWithChildren, useRef } from 'react';
import { useScrollPercentage } from '../../hooks';
import {
  BackgroundOverlay,
  BACKGROUND_POSITION_CSS_VAR,
  StaticBackground,
  StaticBackgroundProps,
} from './styles';

export interface ParallaxBackgroundProps extends StaticBackgroundProps {
  contentContainerRef: React.RefObject<HTMLDivElement>;
}

const MAX_PERCENTAGE = 100;
const MIN_PERCENTAGE = 0;
const BUFFER_PERCENTAGE = 25;

export const BACKGROUND_POSITION_ANIMATION_STRENGTH = 1;
export const BACKGROUND_OPACITY_ANIMATION_STRENGTH = 3;

const ParallaxBackground: React.FC<ParallaxBackgroundProps & PropsWithChildren> = ({
  children,
  contentContainerRef,
  ...props
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const backgroundRef = useScrollPercentage<HTMLDivElement>((scrollPercentage) => {
    if (
      scrollPercentage > MAX_PERCENTAGE + BUFFER_PERCENTAGE ||
      scrollPercentage < MIN_PERCENTAGE - BUFFER_PERCENTAGE
    ) {
      return;
    }

    const percentage = Math.max(Math.min(scrollPercentage, MAX_PERCENTAGE), MIN_PERCENTAGE);
    const background = backgroundRef.current;
    const overlay = overlayRef.current;
    const content = contentContainerRef.current;

    /* istanbul ignore if: difficult to test */
    if (!background || !overlay || !content) {
      return;
    }

    const initial = getComputedStyle(background).getPropertyValue(BACKGROUND_POSITION_CSS_VAR);
    const paddingBottom = Math.round(percentage);

    overlay.style.opacity = `${(percentage / 100) * BACKGROUND_OPACITY_ANIMATION_STRENGTH}`;
    content.style.paddingBottom = `${paddingBottom}px`;
    background.style.backgroundPositionY = `calc(${initial} + ${
      percentage * BACKGROUND_POSITION_ANIMATION_STRENGTH
    }%)`;
  });

  return (
    <StaticBackground data-testid="parallax-background" ref={backgroundRef} {...props}>
      <BackgroundOverlay data-testid="parallax-background-overlay" ref={overlayRef} />
      {children}
    </StaticBackground>
  );
};

export default ParallaxBackground;
