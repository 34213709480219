import { isBreakpointMedOrLess, isBreakpointSmallOrLess } from '@oneaudi/narown-utils-ui';
import bezier from 'bezier-easing';
import { useEffect, useRef } from 'react';
import { startHeights, vinDetailsBottomMargin } from '../../components/VinDetails/styles';
import { useCurrentBreakpoint } from '../useCurrentBreakpoint';
import { useRequestAnimationFrame } from '../useRequestAnimationFrame';

const easing = bezier(0.75, 0.02, 0.5, 1);

const DURATION_IN_MS = 500;
const HALF_DURATION_IN_MS = DURATION_IN_MS / 2;
const CTA_SCROLL = 64;

const getJumpPosition = (isSmallBreakpoint: boolean, heightReference: number) => {
  return (
    heightReference -
    (isSmallBreakpoint ? startHeights.xs : startHeights.m) +
    vinDetailsBottomMargin.xs
  );
};

const getSecondHalfParameters = (currentTime: number, direction: number) => {
  return direction > 0
    ? {
        canAnimate: currentTime > HALF_DURATION_IN_MS,
        time: currentTime - HALF_DURATION_IN_MS,
      }
    : {
        canAnimate: currentTime <= HALF_DURATION_IN_MS,
        time: currentTime,
      };
};

export const useAnimateCtaScroll = (isEditingVin: boolean, heightReference: number) => {
  const currentTimeRef = useRef(DURATION_IN_MS);
  const canAnimateRef = useRef(false);
  const referenceJump = useRef(0);
  const direction = useRef(1);
  const initialPos = useRef(0);
  const breakpoint = useCurrentBreakpoint();

  canAnimateRef.current = isBreakpointMedOrLess(breakpoint);
  referenceJump.current = getJumpPosition(isBreakpointSmallOrLess(breakpoint), heightReference);

  const handleAnimationStep = (delta: number) => {
    if (canAnimateRef.current) {
      let scrollY = initialPos.current;
      if (currentTimeRef.current < DURATION_IN_MS) {
        currentTimeRef.current = Math.min(currentTimeRef.current + delta, DURATION_IN_MS);
        const step = easing(currentTimeRef.current / DURATION_IN_MS);
        scrollY += CTA_SCROLL * step * direction.current;

        const secondHalfParams = getSecondHalfParameters(currentTimeRef.current, direction.current);

        if (secondHalfParams.canAnimate) {
          const secondHalfStep = easing(secondHalfParams.time / HALF_DURATION_IN_MS);
          scrollY += referenceJump.current * secondHalfStep * direction.current;
        } else {
          scrollY -= direction.current > 0 ? 0 : referenceJump.current;
        }

        window.scrollTo(0, scrollY);
      }
    }
  };
  useRequestAnimationFrame(handleAnimationStep);

  useEffect(() => {
    if (canAnimateRef.current) {
      currentTimeRef.current = 0;
      initialPos.current = window.scrollY;
      direction.current = isEditingVin ? -1 : 1;
    }
  }, [isEditingVin]);
};
