import { createContext, PropsWithChildren } from 'react';
import { defaultConfig, type LambdaEndPoints } from '../../config';

export interface ConfigurationContextContent extends PropsWithChildren {
  baseUrl: string;
  lambdaBaseUrl: string;
  narGateway: string;
  lambdaEndPoints: LambdaEndPoints;
}

export const ConfigurationContext = createContext<ConfigurationContextContent>({
  baseUrl: '',
  lambdaBaseUrl: defaultConfig.lambdaBaseUrl,
  narGateway: defaultConfig.narGateway,
  lambdaEndPoints: defaultConfig.endpoints,
});
