import { Button, Layout } from '@audi/audi-ui-react';
import { createMediaQuery } from '@oneaudi/narown-utils-ui';
import styled, { AnyStyledComponent } from 'styled-components';
import { AnimatedContainer } from '../../styled/animation.styles';

export const ICON_SIZE = 22;

export const AnimatedLayout = styled(AnimatedContainer)`
  width: 100%;

  ${createMediaQuery({
    width: {
      l: 'unset',
    },
  })};
`;

export const EnterVinLayout = styled(Layout as AnyStyledComponent)`
  z-index: 1;

  ${createMediaQuery({
    'margin-top': {
      m: '4px',
      l: '22px',
      xl: '12px',
    },
  })};
`;

export const VinFieldContainer = styled.div`
  flex-grow: 2;
  > * {
    width: 100%;

    ${createMediaQuery({
      'margin-bottom': {
        l: '6px',
        xl: '0px',
      },
      width: {
        l: '339px',
      },
    })};
  }
`;

export const FindVinButton = styled(Button as AnyStyledComponent)`
  align-items: center;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  margin-left: 16px;
  margin-top: 35px;
  min-height: ${ICON_SIZE}px;
  min-width: ${ICON_SIZE}px;
  max-height: ${ICON_SIZE}px;
  max-width: ${ICON_SIZE}px;
  padding: 0;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  > div:nth-child(2) {
    height: ${ICON_SIZE}px;
    width: ${ICON_SIZE}px;
  }
`;

export const PopoverBodyContainer = styled.div`
  > img {
    margin-bottom: 16px;
    width: 100%;
  }

  ${createMediaQuery({
    width: {
      xs: '100%',
      m: '295px',
      xl: '347px',
    },
  })};
`;
