import { createMediaQuery, getThemeGreys } from '@oneaudi/narown-utils-ui';
import styled, { keyframes } from 'styled-components';
import {
  ANIMATION_HALF_DURATION,
  AnimatedContainer,
  AnimationStates,
  buildAnimation,
} from '../../styled/animation.styles';

export const startHeights = {
  xs: 117,
  m: 109,
};

export const vinDetailsBottomMargin = {
  xs: 36,
  xl: 32,
};

const buildHeightKeyframes = (from: number, to: number) => keyframes`
  from { height: ${from}px; }
  to { height: ${to}px; }
`;

const buildAnimationObject = (min: number, max: number) => {
  return {
    [AnimationStates.ENTERING]: buildAnimation(
      ANIMATION_HALF_DURATION,
      buildHeightKeyframes(min, max),
    ),
    [AnimationStates.LEAVING]: buildAnimation(
      ANIMATION_HALF_DURATION,
      buildHeightKeyframes(max, min),
    ),
    [AnimationStates.HIDDEN]: '',
    [AnimationStates.IDLE]: '',
  };
};

const heightAnimations = (height: number) => ({
  xs: buildAnimationObject(startHeights.xs, height),
  m: buildAnimationObject(startHeights.m, height),
});

export const OuterContainer = styled.div<{ state: AnimationStates; height: number }>`
  overflow: hidden;

  @media (max-width: 768px) {
    ${(props) => heightAnimations(props.height + 36).xs[props.state]}
  }

  @media (min-width: 769px) and (max-width: 1023px) {
    ${(props) => heightAnimations(props.height + 36).m[props.state]}
  }
`;

export const Container = styled(AnimatedContainer)`
  border: 1px solid ${getThemeGreys(30)};
  box-sizing: border-box;
  margin-bottom: ${vinDetailsBottomMargin.xs}px;
  padding: 16px 24px;
  width: 100%;

  ${createMediaQuery({
    'margin-bottom': {
      xl: `${vinDetailsBottomMargin.xl}px`,
    },

    width: {
      l: '376px',
      xl: '327px',
    },
  })}
`;
