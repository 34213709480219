/**
 * the baseUrl comes from the AEM Registry, and the FeatureAppLoader properly passes
 * down this value. The value can be obtained from the configuration context and hook
 */
export const resolveImage = (src: string, baseUrl: string) => {
  if (baseUrl) {
    const file = src.split('/').pop();
    return `${baseUrl}/fh/images/${file}`;
  }
  return src;
};
