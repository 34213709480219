import { useImagePreloader } from '@oneaudi/narown-utils-ui';
import React, { useEffect, useState } from 'react';

import {
  useAnimateCtaScroll,
  useAuthContext,
  useConfigurationContext,
  useLazyDataFetch,
  useLazyFetch,
  useLocaleService,
  useLazyImage,
} from '../../hooks';
import { ModalActions, VinDetails, VinDetailsResponse } from '../../types';
import { getOverviewPath, getRedirectPath } from '../../utils';
import { SupportRedirectMap } from '../../utils/redirectTo/redirectMap';
import { isVinValid } from '../SubmitVin/isVinValid';
import { CtaHandler } from './CtaHandler';
import { FormHandler } from './FormHandler';
import { ModalErrorTypes, ValidationMessageKey, VinFormErrorProps } from './shared';
import { VinFormError } from './VinFormError';

interface VinFormProps {
  isEditingVin: boolean;
  isError: boolean;
  heightReference: number;
  updateHeightReference: (v: number) => void;
  onUpdateIsError: (v: boolean) => void;
  onGetVehicleDetails: (v: VinDetails) => void;
  onImageLoaded: (src: string, hasImage: boolean) => void;
  onRedirect: (v: string) => void;
  onUpdateEditVin: (v: boolean) => void;
}

export const VinForm: React.FC<VinFormProps> = ({
  isEditingVin,
  isError,
  heightReference,
  updateHeightReference,
  onUpdateIsError,
  onGetVehicleDetails,
  onImageLoaded,
  onRedirect,
  onUpdateEditVin,
}) => {
  const [vin, setVin] = useState('');
  const [hasError, setHasError] = useState(isError);
  const [shouldRetry, setShouldRetry] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [validationMessageKey, setValidationMessageKey] = useState(
    ValidationMessageKey.VALIDATION_ERROR_KEY,
  );

  const { localeService } = useLocaleService();

  const { accessToken } = useAuthContext();

  const [vinDetails, setVinDetails] = useState<VinDetails | null>(null);

  const { loadImage, loading: loadingImage, img, hasImage } = useImagePreloader();
  const { lambdaBaseUrl } = useConfigurationContext();
  const { endPoints, fetchOptions } = useLazyDataFetch();

  const { lazyImage } = useLazyImage(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
  );

  const editVin = () => {
    onUpdateEditVin(true);
  };

  const handleVinChanged = (vinValue: string) => {
    setVin(vinValue);
    const isValid = isVinValid(vinValue);
    if (submitDisabled) {
      setSubmitDisabled(!isValid);
    }
  };

  const handleValidateVin = (isInvalid: boolean) => {
    setInvalid(isInvalid);
    setSubmitDisabled(isInvalid);
    if (isInvalid) {
      setValidationMessageKey(ValidationMessageKey.VALIDATION_ERROR_KEY);
    }
  };

  const actions = {
    [ModalActions.CLOSE]: () => setShouldRetry(true),
    // This NOTHING action is strictly used on storybook
    [ModalActions.NOTHING]: /* istanbul ignore next */ () => ({}),
    [ModalActions.REDIRECT_TO_SUPPORT]: () =>
      onRedirect(getRedirectPath(SupportRedirectMap, localeService)),
  };

  const [errorProps, setErrorProps] = useState<VinFormErrorProps>({
    modalErrorType: ModalErrorTypes.GENERIC_ERROR,
    primaryAction: actions[ModalActions.NOTHING],
    secondaryAction: actions[ModalActions.NOTHING],
  });

  const handleVinDetailsError = (error: string) => {
    if (error.toLowerCase().includes('not found') || error === '404') {
      setInvalid(true);
      setValidationMessageKey(ValidationMessageKey.INVALID_VIN_KEY);
    } else {
      setErrorProps({
        modalErrorType: ModalErrorTypes.GENERIC_ERROR,
        primaryAction: actions[ModalActions.CLOSE],
        secondaryAction: actions[ModalActions.REDIRECT_TO_SUPPORT],
      });
      setHasError(true);
      onUpdateIsError(hasError);
    }
  };

  const handleVinDetailsFetched = (response: VinDetailsResponse) => {
    onGetVehicleDetails(response.data);
    setVinDetails(response.data);
  };

  const handleAddVehicleError = () => {
    setErrorProps({
      modalErrorType: ModalErrorTypes.GENERIC_ERROR,
      primaryAction: actions[ModalActions.CLOSE],
      secondaryAction: actions[ModalActions.REDIRECT_TO_SUPPORT],
    });
    setHasError(true);
    onUpdateIsError(hasError);
  };

  const handleAddVehicleCompleted = () => {
    onRedirect(getOverviewPath(vin));
  };

  const { lazyFetch: getVinDetails, loading: vinDetailsLoading } = useLazyFetch(
    handleVinDetailsFetched,
    handleVinDetailsError,
  );

  const { lazyFetch: addUserVehicle, loading: addUserVehicleLoading } = useLazyFetch(
    handleAddVehicleCompleted,
    handleAddVehicleError,
  );

  useAnimateCtaScroll(isEditingVin, heightReference);

  useEffect(() => {
    if (shouldRetry) {
      setShouldRetry(false);
      setHasError(false);
      onUpdateIsError(false);
    }
  }, [shouldRetry]);

  useEffect(() => {
    if (vinDetails && !loadingImage) {
      onUpdateEditVin(false);
      onImageLoaded(img.src, hasImage);
    }
  }, [isError, loadingImage, vinDetails]);

  const handleOnSubmitVin = (vinValue: string) => {
    setVinDetails(null);
    getVinDetails(
      accessToken as string,
      `${lambdaBaseUrl}${endPoints.vehicle}?vin=${vinValue}`,
      fetchOptions.vinDetails,
    );
    lazyImage(vin, loadImage);
  };

  const formHandlerProps = {
    isEditingVin,
    invalid,
    validationMessageKey,
    onVinChange: handleVinChanged,
    onSubmitVin: handleOnSubmitVin,
    onValidateVin: handleValidateVin,
    model: vinDetails?.model,
    trim: vinDetails?.trim,
    vin,
    year: vinDetails?.year,
    updateHeightReference,
  };

  const ctaHandlerProps = {
    isEditingVin,
    disabled: submitDisabled,
    loading: vinDetailsLoading || addUserVehicleLoading || loadingImage,
    vin,
    onSubmitVin: handleOnSubmitVin,
    onValidateVin: handleValidateVin,
    onAddVin: (v: string) => {
      addUserVehicle(
        accessToken as string,
        `${lambdaBaseUrl}${endPoints.addVehicle}`,
        fetchOptions.addVehicle(JSON.stringify({ vin: v })),
      );
    },
    onEditVin: editVin,
  };

  return (
    <>
      <FormHandler {...formHandlerProps} />
      <CtaHandler {...ctaHandlerProps} />
      <VinFormError
        isError={hasError}
        modalErrorType={errorProps.modalErrorType}
        onPrimaryAction={errorProps.primaryAction}
        onSecondaryAction={errorProps.secondaryAction}
      />
    </>
  );
};
