import { useEffect, useRef, RefObject } from 'react';

const SCROLL_EVENT = 'scroll';

export const useScrollPercentage = <ElementType extends HTMLElement>(
  callback: (percentage: number) => void,
): RefObject<ElementType> => {
  const ref = useRef<ElementType>(null);
  useEffect(() => {
    const onScroll = () => {
      const element = ref.current;
      /* istanbul ignore if: difficult to test because of the lifecycle of a ref */
      if (!element) {
        return;
      }
      const { y } = element.getBoundingClientRect();
      const height = element.offsetHeight;
      const percentage = (-y / height) * 100;
      callback(percentage);
    };
    window.addEventListener(SCROLL_EVENT, onScroll);
    /* istanbul ignore next: unnecessary coverage */
    return () => window.removeEventListener(SCROLL_EVENT, onScroll);
  }, []);
  return ref;
};
