import { PopoverTrigger, TextField } from '@audi/audi-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoIcon } from '@oneaudi/narown-utils-ui';
import { useCurrentBreakpoint } from '../../hooks';
import { AnimatedContainerProps, AnimationStates } from '../../styled/animation.styles';
import { getPopoverPlacement } from '../AddVehicle/utils/getPopoverPlacement';
import { onSubmit } from '../SubmitVin';
import { FindVinPopover } from './FindVin';
import {
  AnimatedLayout,
  EnterVinLayout,
  FindVinButton,
  ICON_SIZE,
  VinFieldContainer,
} from './styles';

const handleVinChange = (
  setVin: React.Dispatch<React.SetStateAction<string>>,
  onChange: (a: string) => void,
) => {
  return (event: React.FormEvent<HTMLInputElement>) => {
    const vinValue = event.currentTarget.value.toUpperCase();
    setVin(vinValue);
    onChange(vinValue);
  };
};

const onEnterKey = (submit: () => void) => (event: KeyboardEvent) => {
  if (event.code === 'Enter') {
    submit();
  }
};

export interface EnterVinProps {
  invalid: boolean;
  validationMessageKey: string;
  vin: string;
  onVinChange: (a: string) => void;
  onSubmitVin: (v: string) => void;
  onValidateVin: (v: boolean) => void;
}

interface AnimatedEnterVinProps extends AnimatedContainerProps, EnterVinProps {}

export const EnterVin: React.FC<AnimatedEnterVinProps> = ({
  invalid,
  state,
  validationMessageKey,
  vin,
  onAnimationEnd,
  onVinChange,
  onSubmitVin,
  onValidateVin,
}) => {
  const { t } = useTranslation();
  const breakpoint = useCurrentBreakpoint();
  const [vinValue, setVinValue] = useState(vin);
  const updateValue = handleVinChange(setVinValue, onVinChange);
  const inputRef = useRef(document.createElement('input'));
  const handleSubmit = onEnterKey(onSubmit(vinValue, onSubmitVin, onValidateVin));

  useEffect(() => {
    inputRef.current?.addEventListener('keydown', handleSubmit);

    return () => inputRef.current?.removeEventListener('keydown', handleSubmit);
  });

  if (state === AnimationStates.HIDDEN) {
    return null;
  }

  return (
    <AnimatedLayout data-testid="enter-vin" state={state} onAnimationEnd={onAnimationEnd}>
      <EnterVinLayout align="start" direction="row" spaceStackEnd="xxxl">
        <VinFieldContainer>
          <TextField
            inputId="text-field--addvin"
            inputMode="text"
            invalid={invalid}
            label={t('addVehicle.enterVin')}
            maxLength={17}
            onChange={updateValue}
            required
            validationMessage={t(validationMessageKey)}
            value={vinValue}
            inputRef={inputRef}
          />
        </VinFieldContainer>
        <PopoverTrigger placement={getPopoverPlacement(breakpoint)} tip>
          {(triggerProps) => (
            <>
              <FindVinButton
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                aria-label={t('addVehicle.whereVin')}
                data-testid="show-popover-button"
                id="nar-add-vehicle-show-popover-button"
                variant="icon-secondary"
                icon={<InfoIcon height={ICON_SIZE} width={ICON_SIZE} />}
                {...triggerProps}
              />
              <FindVinPopover />
            </>
          )}
        </PopoverTrigger>
      </EnterVinLayout>
    </AnimatedLayout>
  );
};
