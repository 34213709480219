import { getEasing } from '@oneaudi/narown-utils-ui';
import styled, { css, Keyframes, keyframes } from 'styled-components';

export const ANIMATION_DURATION = '0.5s';

export const ANIMATION_HALF_DURATION = '0.25s';

export enum AnimationStates {
  ENTERING,
  LEAVING,
  IDLE,
  HIDDEN,
}

export interface AnimatedContainerProps {
  state: AnimationStates;
  onAnimationEnd: () => void;
}

export const OpacityFadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const OpacityFadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

export const buildAnimation = (duration: string, animation: Keyframes) => {
  return css`
    animation: ${animation} ${duration} ${getEasing()};
  `;
};

const FadeIn = buildAnimation(ANIMATION_HALF_DURATION, OpacityFadeIn);
const FadeOut = buildAnimation(ANIMATION_HALF_DURATION, OpacityFadeOut);
const AnimatedContainerStates = {
  [AnimationStates.ENTERING]: FadeIn,
  [AnimationStates.LEAVING]: FadeOut,
  [AnimationStates.IDLE]: '',
  [AnimationStates.HIDDEN]: '',
};

export const AnimatedFade = css<AnimatedContainerProps>`
  ${(props) => AnimatedContainerStates[props.state]}
`;

export const AnimatedContainer = styled.div<AnimatedContainerProps>`
  ${AnimatedFade}
`;
