import { createMediaQuery } from '@oneaudi/narown-utils-ui';
import styled from 'styled-components';
import { reactResponsiveModalStyles } from './reactResponsiveModal.styles';

export const ModalRoot = styled.div`
  ${reactResponsiveModalStyles}

  .nar-add-vehicle-modal {
    padding: 0px;
    max-width: 600px;
  }

  @media (min-width: 640px) {
    .nar-add-vehicle-modal {
      width: 600px;
    }
  }

  .nar-add-vehicle-close-button {
    padding: 12px;
    top: 6px;
    right: 4px;
  }

  // TODO: remove this when WEBSUPPORT-17880 is completed
  // TODO: https://collaboration.msi.audi.com/jira/browse/WEBSUPPORT-17880

  a:focus,
  embed:focus,
  object:focus {
    outline: revert;
  }

  [role='button']:focus-visible,
  button {
    outline: revert;
  }
`;

export const ModalLayout = styled.div`
  margin: 12px;
  padding-bottom: 12px;
  padding-right: 24px;
`;

export const ButtonLayout = styled.div`
  display: flex;

  > button {
    height: 52px;
    min-width: 200px;
  }

  > button:first-child {
    margin-bottom: 4px;
  }

  @media (min-width: 768px) {
    > button:first-child {
      margin-bottom: 0;
    }

    > button:nth-child(2) {
      margin-right: 4px;
    }
  }

  ${createMediaQuery({
    'flex-direction': {
      xs: 'column',
      m: 'row-reverse',
    },

    'justify-content': {
      m: 'end',
    },
  })}
`;
